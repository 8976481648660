import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Starting.css";
import "@fontsource/montserrat";
import ExportToTop from "../ScrollToTop";
import VidItem from "../VidItem";

function Works() {
  return (
    <>
      {/* <HeroSection /> */}
      <WorksInside />
      <ExportToTop />
      {/* <GalleryOfThings /> */}
    </>
  );
}

export default Works;

function WorksInside() {
  const [click, setClick] = useState(false);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <div className="work-section">
        <br />
        <br />
        <br />
        <br />
        <div className="cards">
          <div className="cards__container">
            <div className="cards__wrapper">
              <ul className="cards__items">
                <VidItem
                  src="videos/Botellas playa.mp4"
                  text="TABLE TOP"
                  label=""
                  path="/"
                />
                <VidItem
                  src="videos/Frambuesa.mp4"
                  text="PRODUCT SHOTS"
                  label=""
                  path="/"
                />
                <VidItem
                  src="videos/Amigos mar.mp4"
                  text="STOCK FOOTAGE"
                  label=""
                  path="/"
                />
              </ul>
              <ul className="cards__items">
                <VidItem src="videos/auto.mp4" text="CARS" label="" path="/" />
                <VidItem
                  src="videos/Mujer Tech.mp4"
                  text="FACE SWAP"
                  label=""
                  path="/"
                />
                <VidItem
                  src="videos/Barco.mp4"
                  text="STILL PHOTO"
                  label=""
                  path="/"
                />
              </ul>
              <ul className="cards__items">
                <VidItem
                  src="videos/Ciceron.mp4"
                  text="MOODBOARDS & ANIMATICS"
                  label=""
                  path="/"
                />
                <VidItem
                  src="videos/Buzo.mp4"
                  text="SNACK CONTENT"
                  label=""
                  path="/"
                />
                <VidItem
                  src="videos/Humo.mp4"
                  text="OTHERS"
                  label=""
                  path="/"
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
