import React from "react";

function VidItem(props) {
  return (
    <>
      <li className="cards__item">
        <figure className="cards__item__pic-wrap">
          <video
            className="cards__item__video"
            src={props.src}
            alt={props.text}
            loop
            muted
            onMouseOver={(e) => e.target.play()}
            onMouseOut={(e) => e.target.pause()}
          />
          <div className="overlay-text">{props.text}</div>
        </figure>
      </li>
    </>
  );
}

export default VidItem;
