import React from "react";
import "../../App.css";
import "../Cards.css";
import ExportToTop from "../ScrollToTop";
import Starting from "../Starting";

function Home() {
  return (
    <>
      {/* <Cards /> */}
      <Starting />
      <ExportToTop />
    </>
  );
}

export default Home;
