import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Starting.css";
import "@fontsource/montserrat";
import ExportToTop from "./ScrollToTop";
// import VidItem from "./VidItem";
// import Navbar from "./Navbar";
import Works from "./pages/Works";
import Contact from "./pages/Contact";
import About from "./pages/About";

function Starting() {
  return (
    <>
      <StartingInside />
      <About />
      <Works />
      <EmptySpace />
      <Contact />
      <ExportToTop />
    </>
  );
}

export default Starting;

function EmptySpace() {
  return (
    <>
      <div className="empty-space"></div>
    </>
  );
}

function StartingInside() {
  const [click, setClick] = useState(false);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <div className="full-screen-section">
        <video
          className="background-video"
          src="/videos/home.mp4"
          type="video/mp4"
          autoPlay
          loop
          muted
        />
        <p>Introducing</p>
        <h1>MIRAGE</h1>
        <br></br>
        <p>LUMIERE AI-Powered Image creation workflow. </p>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <p>Transforming the way videos are created.</p>
        <br></br>
        <br></br>
        <p>Making it easier, faster, and more efficient.</p>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>
      {/* <div className="full-screen-section">
        <h2>
          Unlock the future of visual creativity with AI-powered image
          generation. <br />
          We’re here to help you bring powerful, captivating visuals to life.
        </h2>
        <br />
        <h2>
          Partnering with industry leaders, top marketing agencies, production
          teams, <br />
          and social media platforms, we create stunning videos that maximize
          impact and efficiency.
        </h2>
        <br />
        <br />
        <h2>
          Explore samples of our work while we fine-tune our site for the
          ultimate experience.
        </h2>
      </div> */}
      {/* <div className="full-screen-section">
        <ul className="menu-list">
          <li className="mButton">
            <Link to="/works" className="mLinks" onClick={closeMobileMenu}>
              Works
            </Link>
          </li>
          <li className="mButton">
            <Link to="/about" className="mLinks" onClick={closeMobileMenu}>
              About
            </Link>
          </li>
          <li className="mButton">
            <Link to="/contact" className="mLinks" onClick={closeMobileMenu}>
              Contact
            </Link>
          </li>
        </ul>
      </div> */}
    </>
  );
}
