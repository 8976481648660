import React, { useState } from "react";
import "../Starting.css";
import ExportToTop from "../ScrollToTop";

function Contact() {
  return (
    <>
      <ContactInside />
      <ExportToTop />
    </>
  );
}

export default Contact;

function ContactInside() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://nugjoryc8e.execute-api.sa-east-1.amazonaws.com/prod/contact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const result = await response.json();
      if (response.ok) {
        alert("Email sent successfully!");
      } else {
        alert("Failed to send email.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred.");
    }
  };

  return (
    <div className="full-screen-section contactee">
      <div className="contact-container">
        <div className="contact-left">
          <h2 className="keep-in-touch">KEEP IN TOUCH</h2>
          <h2>Contact us</h2>
          <form className="contact-form" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <button type="submit">Send Message</button>
          </form>
        </div>

        <div className="contact-right">
          <div className="contact-info">
            <h2>EMAIL</h2>
            <h2>hello@loslumiere.com</h2>
            <br />
            <h2>BUENOS AIRES OFFICE</h2>
            <h2>Av Santa Fe 1432 Piso 14</h2>
          </div>
        </div>
      </div>
    </div>
  );
}
