import React, { useState } from "react";
import "../Starting.css";
import ExportToTop from "../ScrollToTop";
import Footer from "../Footer";

function About() {
  return (
    <>
      <AboutInside />
      <ExportToTop />
      {/* <GalleryOfThings /> */}
    </>
  );
}

export default About;

function AboutInside() {
  return (
    <>
      <div className="full-screen-section contactee">
        {/* <video
          className="background-video"
          src="/videos/about.mp4"
          type="video/mp4"
          autoPlay
          loop
          muted
        /> */}
        <br></br>
        <h3>We create a custom workflow to build powerful images.</h3>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h3>
          We have experience with leading clients, marketing agencies,
          production companies, and social media platforms to create stunning
          videos while optmizing resources.
        </h3>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h3>Partnered with:</h3>
        <br></br>
        <br></br>
        <div className="social-icons cAbout">
          <a
            className="social-icon-link owl"
            href="https://www.owlstudio.tv"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Owl Studio"
          >
            <img src="images/owl-negro.png" className="custom-icon" />
          </a>

          <a
            className="social-icon-link another"
            href="https://www.anotherpolitestudio.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Another Polite Studio"
          >
            <img src="images/another_logo_negro.png" className="custom-icon" />
          </a>
        </div>
        <br></br>
      </div>
    </>
  );
}
